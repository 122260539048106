import { defineStore } from "pinia"
import { requestVipPrice, requestWxPay, requestZfbPay, requestIsPayOrder, requestToBePaid } from "@/service/modular/vip"

const UseStoreVip = defineStore("storeVip", {
  state: () => ({
    vipPrice: [],
    topicPayDialog: false,
    videoPayDialog: false
  }),
  actions: {
    requestVipPriceAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestVipPrice(parameter)
        if (res.code == 200) {
          
          this.vipPrice = res.data
          resolve(res.data)
        }
      })
    },
    requestWxPayAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestWxPay(parameter)
        if (res.code == 200) {
          resolve(res.data)
        }
      })
    },
    requestZfbPayAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestZfbPay(parameter)
        if (res.code == 200) {
          resolve(res.data)
        }
      })
    },
    requestIsPayOrderAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestIsPayOrder(parameter)
        if (res.code == 200) {
          resolve(res.data)
        }
      })
    },
    requestToBePaidAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestToBePaid(parameter)
        if (res.code == 200) {
          resolve(res.data)
        }
      })
    }
  }
})

export default UseStoreVip
