// 防抖
export const mydebounce = (fn, delay, immediate = false) => {
  let timer = null
  let isInvoke = false
  const _debounce = function (...args) {
    if (timer) clearTimeout(timer)
    if (!isInvoke && immediate) {
      fn.call(this, args)
      isInvoke = true
      return
    }
    timer = setTimeout(() => {
      fn.call(this, args)
      timer = null
      isInvoke = false
    }, delay)
  }
  _debounce.cancel = function () {
    if (timer) clearTimeout(timer)
    timer = null
    isInvoke = false
  }
  return _debounce
}

// 节流
export const myThrottle = (fn, interval, immediate = true) => {
  let startTime = 0
  const _throttle = function (...args) {
    const nowTime = new Date().getTime()
    if (!immediate && startTime === 0) {
      startTime = nowTime
    }
    const waitTime = interval - (nowTime - startTime)
    if (waitTime <= 0) {
      fn.apply(this, args)
      startTime = nowTime
    }
  }
  return _throttle
}
