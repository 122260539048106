import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-67fbca37"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "captcha",
  style: {
    "display": "flex"
  }
};
const _hoisted_2 = {
  ref: "canvas",
  width: "100",
  height: "40"
};
const _hoisted_3 = {
  class: "valicode-btn"
};
import { ref, onMounted } from "vue";
export default {
  __name: 'index',
  emits: ["getCode"],
  setup(__props, {
    emit: __emit
  }) {
    let emits = __emit;
    let canvasRef = ref(null);
    let ctx = ref(null);
    let code = ref("");
    let chars = "abcdefghijklmnopqrstuvwxyz0123456789";
    let charLength = chars.length;
    function getRandomChar() {
      return chars.charAt(Math.floor(Math.random() * charLength));
    }
    function draw() {
      if (!ctx.value) return;
      ctx.value.clearRect(0, 0, canvasRef.value?.width, canvasRef.value?.height);
      let x = 10;
      for (let i = 0; i < 4; i++) {
        const c = getRandomChar();
        code.value += c;
        ctx.value.font = "bold 20px Arial";
        ctx.value.fillStyle = "#333";
        ctx.value.fillText(c, x, 25);
        x += 20;
      }
      for (let i = 0; i < 10; i++) {
        ctx.value.strokeStyle = "#ccc";
        ctx.value.beginPath();
        ctx.value.moveTo(Math.random() * 100, Math.random() * 40);
        ctx.value.lineTo(Math.random() * 100, Math.random() * 40);
        ctx.value.stroke();
      }
      emits("getCode", code.value);
    }
    function refresh() {
      code.value = "";
      draw();
      emits("getCode", code.value);
    }
    onMounted(() => {
      let code = ref("");
      canvasRef.value = document.querySelector("canvas");
      emits("getCode", code.value);
      ctx.value = canvasRef.value?.getContext("2d");
      draw();
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("canvas", _hoisted_2, null, 512)]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_button, {
        type: "danger",
        link: "",
        onClick: refresh
      }, {
        default: _withCtx(() => [_createTextVNode("换一张")]),
        _: 1
      })])], 64);
    };
  }
};