import LysRequest from "../request/index"

// 获取所有会员价格
export const requestVipPrice = (data) => {
  return LysRequest.request({
    url: `/userPrice/getUserPriceByType`,
    method: "post",
    data
  })
}

// 微信支付-用户会员
export const requestWxPay = (data) => {
  return LysRequest.request({
    url: `/pay/wxUserPay`,
    method: "post",
    data
  })
}

// 支付宝支付-用户会员
export const requestZfbPay = (data) => {
  return LysRequest.request({
    url: `/pay/aliUserPay`,
    method: "post",
    data
  })
}

// 查询用户是否存在未付款订单
export const requestIsPayOrder = (data) => {
  return LysRequest.request({
    url: `/userBookOrder/searchNoPayOrder`,
    method: "post",
    data
  })
}

// 待付款支付
export const requestToBePaid = (data) => {
  return LysRequest.request({
    url: `/userBookOrder/pay`,
    method: "post",
    data
  })
}
