import LysRequest from "../request/index"

// 查询合作伙伴
export const requestCooperateList = (data) => {
  return LysRequest.request({
    url: `/partner/searchPartnerPage`,
    method: "post",
    data
  })
}
