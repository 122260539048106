import LysRequest from "../request/index"

// 获取年级和学科 （题库）
export const requestGradesAndSubjects = () => {
  return LysRequest.request({
    url: "/selfQuestion/getGradesAndSubjects",
    method: "get"
  })
}

// 获取题型 （题库）
export const requestQuestionType = () => {
  return LysRequest.request({
    url: "/selfQuestion/getTypes",
    method: "get"
  })
}

// 查询题库列表 (题库)
export const requestQuestionList = (data) => {
  return LysRequest.request({
    url: "/selfQuestion/getPopularList",
    method: "post",
    data
  })
}

// 获取科目年级目录信息
export const requestNav = () => {
  return LysRequest.request({
    url: "/subject/catalog",
    method: "get"
  })
}

// 获取对应科目的书
// subjectId	科目
// type	课内书:1,课外书:2
export const requestBook = (data) => {
  return LysRequest.request({
    url: `/book/getBySubjectId`,
    method: "post",
    data
  })
}

// 获取热门课程排行榜
export const requestBookRank = (data) => {
  return LysRequest.request({
    url: `/book/getBookRank`,
    method: "post",
    data
  })
}

// 添加用户学习课程
export const requestUserAddBook = (data) => {
  return LysRequest.request({
    url: `/userSubject/add/${data.userId}/${data.bookId}`,
    method: "post"
  })
}

// // 根据用户id获取用户的收藏书籍
// export const requestUserBookCollect = (userId)=>{
//   return LysRequest.request({
//     url:`/userBookCollection/byUserId/${userId}`,
//     method:"get",
//   })
// }

// 添加用户书籍到收藏
export const requestAddBookCollect = (data) => {
  return LysRequest.request({
    url: `/userBookCollectionBox/add/${data.userId}/${data.bookId}`,
    method: "post"
  })
}

// 删除用户收藏书籍通过书籍id
export const requestDeleteBookCollect = (data) => {
  return LysRequest.request({
    url: `/userBookCollectionBox/remove/${data.userId}/${data.bookId}`,
    method: "post"
  })
}

// 书籍id获取书籍价格
export const requestBookPrice = (bookId) => {
  return LysRequest.request({
    url: `/bookPrice/getBookPrice/${bookId}`,
    method: "get"
  })
}

// 支付宝支付接口
export const requestZfbPay = (data) => {
  return LysRequest.request({
    url: `/pay/aliPay`,
    method: "post",
    data
  })
}

// 微信支付接口
export const requestWxPay = (data) => {
  return LysRequest.request({
    url: `/pay/wxPay`,
    method: "post",
    data
  })
}

// 查询订单状态
export const requestPayStatus = (orderNumber) => {
  return LysRequest.request({
    url: `/pay/status/${orderNumber}`,
    method: "post"
  })
}

// 客服聊天记录
export const requestMessageList = (data) => {
  return LysRequest.request({
    url: `/communicate/communicateByPage`,
    method: "post",
    data
  })
}

// 用户留言发送信息
export const requestSendMessage = (data) => {
  return LysRequest.request({
    url: `/communicate/send`,
    method: "post",
    data
  })
}

// 修改用户 在线或离线 状态
export const requestUserOnLine = (data) => {
  return LysRequest.request({
    url: `/communicate/modifyUserOnLine`,
    method: "post",
    data
  })
}

// 当前用户未读消息数量
export const requestHintMessageCount = (data) => {
  return LysRequest.request({
    url: `/userNotice/searchUserNoticeCount`,
    method: "post",
    data
  })
}

// 查询系统通知-未读
export const requestHintMessageUnread = (data) => {
  return LysRequest.request({
    url: `/userNotice/searchUserNoticeNoReadList`,
    method: "post",
    data
  })
}

// 热门视频推荐
export const requestHotVideoList = (data) => {
  return LysRequest.request({
    url: `/video/recommendList`,
    method: "post",
    data
  })
}


// 乐学乐器栏目
export const requestInstrumentsList = (data) => {
  return LysRequest.request({
    url: `/instruments/list`,
    method: "get",
    data
  })
}