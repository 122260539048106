import LysRequest from "../request/index"

// 详情页数据
export const requestBookDetails = (data) => {
  return LysRequest.request({
    url: "/book/getBookInfo",
    method: "post",
    data
  })
}

// 添加用户书籍到购物车
export const requestCartAdd = (parameter) => {
  return LysRequest.request({
    url: `/userBookCollection/add/${parameter.userId}/${parameter.bookId}/${parameter.bookPriceId}`,
    method: "post"
  })
}

// 书籍id获取书籍价格
export const requestBookPrice = (bookId) => {
  return LysRequest.request({
    url: `/bookPrice/getBookPrice/${bookId}`,
    method: "get"
  })
}

// 查询订单状态
export const requestPayStatus = (orderNumber) => {
  return LysRequest.request({
    url: `/pay/status/${orderNumber}`,
    method: "post"
  })
}

// 获取推荐书籍
export const requestRecommendBook = (data) => {
  return LysRequest.request({
    url: `/book/getRecommendBookInfo`,
    method: "post",
    data
  })
}

// 根据书籍id获取书籍目录
export const requestBookContents = (data) => {
  return LysRequest.request({
    url: `/book/catalogByBookId/${data.bookId}`,
    method: "get"
  })
}
