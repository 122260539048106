import { defineStore } from "pinia"
import { requestCooperateList } from "@/service/modular/cooperate"

const UseStoreCooperate = defineStore("storeCooperate", {
  state: () => ({
    list: []
  }),
  actions: {
    async requestCooperateListAction(parameter) {
      let res = await requestCooperateList(parameter)
      if (res.code == 200) {
        this.list = res.data.records
      }
    }
  }
})

export default UseStoreCooperate
