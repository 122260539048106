import { defineStore } from "pinia";
import UseStoreCart from "./cart.js";
import { requestBookDetails, requestBookPrice, requestRecommendBook, requestCartAdd, requestBookContents, requestPayStatus } from "@/service/modular/details";
const UseStoreDetails = defineStore("storeDetails", {
  state: () => ({
    data: [],
    bookSku: [],
    recommendBook: [],
    bookContents: [],
    storeCart: UseStoreCart()
  }),
  actions: {
    async requestBookDetailsAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestBookDetails(parameter);
        if (res.code == 200) {
          this.data = res.data;
          resolve(res.data);
        }
      });
    },
    async requestCartAddActive(parameter) {
      let res = await requestCartAdd(parameter);
      if (res.code == 200) {
        this.storeCart.requestCartAction(parameter.userId);
        ElMessage({
          message: "添加成功",
          type: "success"
        });
      }
    },
    async requestBookPriceAction(bookId) {
      return new Promise(async (resolve, reject) => {
        let res = await requestBookPrice(bookId);
        if (res.code == 200) {
          this.bookSku = res.data;
          resolve(true);
        }
      });
    },
    async requestRecommendBookAction(parameter) {
      let res = await requestRecommendBook(parameter);
      this.recommendBook = res.data;
    },
    // 书籍目录
    async requestBookContentsAction(parameter) {
      let res = await requestBookContents(parameter);
      this.bookContents = res.data;
    },
    // 订单状态
    async requestPayStatusAction(orderNumber) {
      return new Promise(async (resolve, reject) => {
        let res = await requestPayStatus(orderNumber);
        if (res.code == 200 && res.data) {
          resolve(res.data);
        }
      });
    }
  }
});
export default UseStoreDetails;