export const optionsFiltration = (value) => {
  // 数据的过滤
  let data = value.replaceAll("&nbsp;", "")
  let f1 = data.split("</行分割>")
  let options = []
  f1.map((item, index) => {
    let f2 = item.split("</选项分割>")
    options[index] = {}
    options[index].option = f2[0]?.trim()
    options[index].content = f2[1]?.trim()
  })
  return options
}


//  词条数据过滤
export const entryFiltration = (data)=>{
  let regex = /★(videoId|audioId):\d+★/g
  let matches = data.match(regex)
  if (matches?.length > 0) {
    let templtae = data
    for (const key of matches) {
      let id = key.match(/\d+/g)
      if (key.indexOf("videoId") != -1) {
        templtae = templtae.replace(
          key,
          `<span id="${id}" class='explain-play-icon iconfont icon-shipin2' onclick="explainIconClick(${id},1)"></span>`
        )
      } else {
        templtae = templtae.replace(
          key,
          `<span id="${id}" class='explain-play-icon iconfont icon-yinpin' onclick="explainIconClick(${id},2)"></span>`
        )
      }
    }
    data = templtae
  }
  return data
}