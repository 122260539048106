import { defineStore } from "pinia";
import { requestCart, requestUserBalance, requestCartBook, requestCartDelete, requestWxPay, requestZfbPay, requestPayStatus, requestRemoveByIds } from "@/service/modular/cart";
const UseStoreCart = defineStore("storeCart", {
  state: () => ({
    cartList: [],
    recommendedBook: [],
    userBalanceDetail: []
  }),
  actions: {
    // 购物车数据列表
    async requestCartAction(userId) {
      let res = await requestCart(userId);
      this.cartList = res.data;
    },
    // 批量删除
    async requestRemoveByIdsAction(parameter, userId) {
      let res = await requestRemoveByIds(parameter);
      if (res.code == 200) {
        ElMessage({
          message: "删除成功",
          type: "success"
        });
        this.requestCartAction(userId);
      }
    },
    // 微信接口
    async requestWxPayAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestWxPay(parameter);
        if (res.code == 200) {
          resolve(res.data);
        }
      });
    },
    // 支付宝接口
    async requestPayAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestZfbPay(parameter);
        if (res.code == 200) {
          resolve(res.data);
        }
      });
    },
    // 用户余额明细
    async requestUserBalanceAction(parameter) {
      let res = await requestUserBalance(parameter);
      if (res.code == 200) {
        this.userBalanceDetail = res.data;
      }
    },
    // 订单状态
    async requestPayStatusAction(orderNumber) {
      return new Promise(async (resolve, reject) => {
        let res = await requestPayStatus(orderNumber);
        if (res.code == 200 && res.data) {
          resolve(res.data);
        }
      });
    }

    // async requestCartBookAction(){
    //   let res = await requestCartBook()
    //   this.recommendedBook = res.data
    // },

    // async requestCartDeleteAction(parameter){
    //   let res = await requestCartDelete(parameter)
    //   if(res.code == 200){
    //     ElMessage({ message: '删除成功', type: 'success'});
    //     this.requestCartAction(parameter.userId)
    //   }
    // },
  }
});

export default UseStoreCart;