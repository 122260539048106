import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-258092a0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "position"
};
const _hoisted_2 = {
  class: "empty"
};
import { ref } from "vue";
export default {
  __name: 'index',
  props: {
    text: {
      type: String,
      default: "暂无内容"
    },
    slot: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "default"
    }
  },
  setup(__props) {
    let type = ref({
      default: {
        icon: "110px",
        text: "24px"
      },
      small: {
        icon: "60px",
        text: "16px"
      }
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, [_createElementVNode("i", {
        class: "iconfont icon-meiyoushuju",
        style: _normalizeStyle({
          fontSize: _unref(type)[__props.size].icon
        })
      }, null, 4), !__props.slot ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: "text",
        style: _normalizeStyle({
          fontSize: _unref(type)[__props.size].text
        })
      }, _toDisplayString(__props.text), 5)) : _renderSlot(_ctx.$slots, "text", {
        key: 1
      })])])]);
    };
  }
};