import LysRequest from "../request/index"

// 获取个人信息
export const requestUserInfo = () => {
  return LysRequest.request({
    url: "/user/infoByToken",
    method: "post"
  })
}

// 修改个人信息
export const requestEditUserInfo = (data) => {
  console.log(data)
  return LysRequest.request({
    url: `/user/modify`,
    method: "post",
    data
  })
}

// 获取用户学习课程
export const requestUserBooks = (data) => {
  return LysRequest.request({
    url: `/userSubject/selectBookByUserId`,
    method: "post",
    data
  })
}

// 根据书籍ID获取目录
export const requestBookCatalogue = (bookId,userId) => {
  return LysRequest.request({
    url: `/book/catalogByBookId/${bookId}?userId=${userId}`,
    method: "get"
  })
}

// 根据书籍ID获取试卷
export const requestExamCatalogue = (bookId) => {
  return LysRequest.request({
    url: `/exam/byBookId/${bookId}`,
    method: "get"
  })
}

// 根据用户ID获取已记录音频的书籍列表
export const requestUserAudioBook = (userId) => {
  return LysRequest.request({
    url: `/userWordAudio/byUserId/${userId}`,
    method: "get"
  })
}

// 根据书籍ID获取所有音频
export const requestBookAudio = (data) => {
  return LysRequest.request({
    url: `/userWordAudio/byBookId/${data.bookId}/${data.userId}`,
    method: "get"
  })
}

// 根据音频ID删除音频
export const requestDeleteAudio = (id) => {
  return LysRequest.request({
    url: `/userWordAudio/delete/${id}`,
    method: "DELETE"
  })
}

// 获取用户课程的科目
export const requestUserSubjectName = (data) => {
  return LysRequest.request({
    url: `/userSubject/getSubjectName`,
    method: "post",
    data
  })
}

// 获取用户笔记封面
export const requestUserNoteBook = (data) => {
  return LysRequest.request({
    url: `/notebook/selectBookByUserId`,
    method: "post",
    data
  })
}

// 根据书籍ID和用户ID获取笔记
export const requestBookNote = (data) => {
  return LysRequest.request({
    url: `/notebook/byBookIdAndUserId/${data.bookId}/${data.userId}`,
    method: "get"
  })
}

// 根据用户ID获取课程表
export const reqUserIdCourse = (data) => {
  return LysRequest.request({
    url: `/course/getByUserId`,
    method: "post",
    data
  })
}

// 书籍最近记录
export const requestBookRecord = (parameter) => {
  return LysRequest.request({
    url: `/userBookRecord/bookRecord/${parameter.bookId}/${parameter.userId}`,
    method: "get"
  })
}

// 书籍最近获取科目下拉
export const requestSubjectName = (data) => {
  return LysRequest.request({
    url: `/userBookRecord/getSubjectName`,
    method: "post",
    data
  })
}

// 书籍最近根据用户id和科目获取用户的学习书籍
export const requestSelectBookByUserId = (data) => {
  return LysRequest.request({
    url: `/userBookRecord/selectBookByUserId`,
    method: "post",
    data
  })
}

// 获取所有笔记科目
export const requestAllSubjectName = () => {
  return LysRequest.request({
    url: `/notebook/getAllSubjectName`,
    method: "post"
  })
}

// 查询剩余阅读次数
export const requestUserVipRead = (data) => {
  return LysRequest.request({
    url: `/userVipRead/getIsReadLimitCount`,
    method: "post",
    data
  })
}

// 获取上海区域列表
export const requestAddressList = (data) => {
  return LysRequest.request({
    url: `/user/getAreaList`,
    method: "post",
    data
  })
}

// 模糊查询学校
export const requestVagueSchoolName = (data) => {
  return LysRequest.request({
    url: `/user/getSchoolByName`,
    method: "post",
    data
  })
}

// 获取学段和年级
export const requestGradesAndSubjects = () => {
  return LysRequest.request({
    url: `/user/getGradesAndSubjects`,
    method: "get"
  })
}

// 根据书籍id获取书籍收藏目录
export const reqBookCollectionCatalog = (data)=>{
  return LysRequest.request({
    url: `/book/collectionCatalogByBookId`,
    method: "post",
    data
  })
}


// 中心收藏--- 智库 -- 题目列表
export const requestCollectTopic = (data) => {
  return LysRequest.request({
    url: `/selfQuestion/getCollectionPersonList`,
    method: "post",
    data
  })
}

// 中心笔记--- 视频 -- 视频列表
export const requestVideoNoteList = (data) => {
  return LysRequest.request({
    url: `/videoNote/getAllUserVideoNote`,
    method: "post",
    data
  })
}


// 中心笔记--- 智库 -- 题目列表
export const requestTopicNoteTopic = (data) => {
  return LysRequest.request({
    url: `/userQuestionNote/getAllUserQuestionNote`,
    method: "post",
    data
  })
}

// 中心笔记--- 智库 -- 题目笔记删除
export const requestTopicNoteTopicDel = (data) => {
  return LysRequest.request({
    url: `/userQuestionNote/deleteByQuestionId`,
    method: "post",
    data
  })
}

// 中心收藏--- 视频 -- 科目列表
export const requestvideoCollectSubject = (userId) => {
  return LysRequest.request({
    url: `/video/favoriteSubjectList/${userId}`,
    method: "post"
  })
}

// 中心收藏--- 视频 -- 视频列表
export const requestvideoCollect = (data) => {
  return LysRequest.request({
    url: `/video/favoriteList`,
    method: "post",
    data
  })
}

// 中心收藏--- 课本 -- 科目列表
export const requestBookCollectSubject = (data) => {
  return LysRequest.request({
    url: `/userBookCollectionBox/getCollectionPersonSubjectList`,
    method: "post",
    data
  })
}

// 中心收藏--- 课本 -- 收藏列表
export const requestBookCollect = (data) => {
  return LysRequest.request({
    url: `/userBookCollectionBox/getCollectionPersonList`,
    method: "post",
    data
  })
}

// 中心错题--- 智库 -- 科目列表
export const requestTopicWrongSubject = (data) => {
  return LysRequest.request({
    url: `/selfQuestion/getCorrectPersonSubjectList`,
    method: "post",
    data
  })
}

// 中心错题--- 智库 -- 题目列表
export const requestTopicWrong = (data) => {
  return LysRequest.request({
    url: `/selfQuestion/getCorrectList`,
    method: "post",
    data
  })
}

// 中心错题--- 智库 -- 题目删除
export const requestTopicWrongDel = (data) => {
  return LysRequest.request({
    url: `/selfQuestion/removeCorrect`,
    method: "post",
    data
  })
}

// 记录用户阅读书籍
export const requestUserBookRecord = (data) => {
  return LysRequest.request({
    url: `/book/bookRecord`,
    method: "post",
    data
  })
}

// 获取书籍阅读记录列表
export const requestUserBookRecordList = (data) => {
  return LysRequest.request({
    url: `/book/getBookRecordList`,
    method: "post",
    data
  })
}

// 获取书籍最近一条阅读记录
export const requestGetUserBookRecord = (data) => {
  return LysRequest.request({
    url: `/book/getBookRecord`,
    method: "post",
    data
  })
}

// 用户是否签到
export const requestUserWhetherSignIn = (userId) => {
  return LysRequest.request({
    url: `/userVipLevel/isSignIn/${userId}`,
    method: "get"
  })
}

// 用户签到
export const requestUserSignIn = (data) => {
  return LysRequest.request({
    url: `/userVipLevel/signIn`,
    method: "post",
    data
  })
}

// 新建收藏夹
export const requestCollectionFavorite = (data) => {
  return LysRequest.request({
    url: `/collectionFavorite/save`,
    method: "post",
    data
  })
}

// 根据用户Id和类型获取收藏夹
export const requestCollectionFavoriteList = (data) => {
  return LysRequest.request({
    url: `/collectionFavorite/byUserIdAndType`,
    method: "post",
    data
  })
}

// 删除收藏夹
export const requestCollectionFavoriteDel = (data) => {
  return LysRequest.request({
    url: `/collectionFavorite/delete`,
    method: "post",
    data
  })
}

// 编辑收藏夹
export const requestCollectionFavoriteEdit = (data) => {
  return LysRequest.request({
    url: `/collectionFavorite/update`,
    method: "post",
    data
  })
}

// 将数据添加到收藏夹
export const requestCollectionFavoriteAddMove = (data) => {
  return LysRequest.request({
    url: `/collectionFavorite/addData`,
    method: "post",
    data
  })
}

// 将数据从收藏夹里删除
export const requestCollectionFavoriteDeleteMove = (data) => {
  return LysRequest.request({
    url: `/collectionFavorite/removeData`,
    method: "post",
    data
  })
}

// 笔记中心 - 个人笔记 - 查询列表
export const requestUserNoteList = (data) => {
  return LysRequest.request({
    url: `/userNote/searchUserNoteList`,
    method: "post",
    data
  })
}

// 笔记中心 - 个人笔记 - 保存修改
export const requestUserNoteUpdate = (data) => {
  return LysRequest.request({
    url: `/userNote/saveOrUpdate`,
    method: "post",
    data
  })
}

// 笔记中心 - 个人笔记 - 查询内容
export const requestUserNoteInfo = (data) => {
  return LysRequest.request({
    url: `/userNote/info`,
    method: "post",
    data
  })
}

// 笔记中心 - 个人笔记 - 删除
export const requestUserNoteDel = (data) => {
  return LysRequest.request({
    url: `/userNote/delete`,
    method: "post",
    data
  })
}

// 学习记录 - 智库
export const requestUserTopicRecords = (data) => {
  return LysRequest.request({
    url: `/userStudyRecord/searchUserExamPage`,
    method: "post",
    data
  })
}


// 家庭作业
export const requestUserHomeWork = (data) => {
  return LysRequest.request({
    url: `/homeWork/searchUserHomeWorkPage`,
    method: "post",
    data
  })
}

// 家庭作业 id查询答题情况
export const requestUserHomeWorkDetail = (data) => {
  return LysRequest.request({
    url: `/homeWork/searchUserHomeWorkDetail`,
    method: "post",
    data
  })
}

// 家庭作业提交
export const requestUserHomeWorkSubmit = (data) => {
  return LysRequest.request({
    url: `/homeWork/submitHomeWork`,
    method: "post",
    data
  })
}


// 分页查询线下课程价格
export const reqUserCoursesShop = (data)=>{
  return LysRequest.request({
    url: `/coursePrice/selectByPage`,
    method: "post",
    data
  })
}

// 线下课程-筛选-年级段
export const reqCoursesShopGrades = ()=>{
  return LysRequest.request({
    url: `/coursePrice/getCourseGrades`,
    method: "post"
  })
}

// 线下课程-筛选-年级
export const reqCoursesShopGradeSections = (gradeId)=>{
  return LysRequest.request({
    url: `/coursePrice/getGradeSection/${gradeId}`,
    method: "get"
  })
}

// 线下课程-筛选-学科
export const reqCoursesShopSubject = (data)=>{
  return LysRequest.request({
    url: `/coursePrice/getCourseSubject`,
    method: "post",
    data
  })
}

// 线下课程-筛选-套餐
export const reqCoursesShopSku = (data)=>{
  return LysRequest.request({
    url: `/coursePrice/getCourseSelect`,
    method: "post",
    data
  })
}

// 线下课程-订单记录
export const reqCoursesShopOrder = (data)=>{
  return LysRequest.request({
    url: `/course/selectUserCourseRecordList`,
    method: "post",
    data
  })
}

// 查询用户当月课程状态
export const reqUserCourses = (data)=>{
  return LysRequest.request({
    url: `/course/getUserCourse`,
    method: "post",
    data
  })
}

// 查询用户当天课程状态
export const reqUserDayCourses = (data)=>{
  return LysRequest.request({
    url: `/course/getUserCourseStatus`,
    method: "post",
    data
  })
}


// 查询用户当天课程
export const reqUserCoursesDetails = (data)=>{
  return LysRequest.request({
    url: `/course/getUserDayCourse`,
    method: "post",
    data
  })
}

// 查询用户当天作业情况
export const reqUserTaskDetails = (data)=>{
  return LysRequest.request({
    url: `/homeWork/searchUserHomeWorkDay`,
    method: "post",
    data
  })
}

// 课程签到
export const reqUserCoursesSign = (data)=>{
  return LysRequest.request({
    url: `/course/sign`,
    method: "post",
    data
  })
}

// 查询用户课程使用记录
export const reqUserCoursesUsageRecord = (data)=>{
  return LysRequest.request({
    url: `/course/queryCourseUserOperateRecord`,
    method: "post",
    data
  })
}

// 检查是否不存在未签到课程
export const reqCheckUserCourse = (data)=>{
  return LysRequest.request({
    url: `/course/checkUserCourse`,
    method: "post",
    data
  })
}

// 查询当天预习列表
export const reqSearchPreviewDay = (data)=>{
  return LysRequest.request({
    url: `/coursePreview/searchPreviewDay`,
    method: "post",
    data
  })
}

// 完成预习
export const reqPreviewComplete = (data)=>{
  return LysRequest.request({
    url: `/coursePreview/finishPreviewStatus`,
    method: "post",
    data
  })
}

// 用户广播信息
export const reqUserBroadcast = (data)=>{
  return LysRequest.request({
    url: `/userBroadcast/queryUserBroadcast`,
    method: "post",
    data
  })
}

// 根据书籍id获取书籍笔记目录
export const reqNoteCatalogByBookId = (data)=>{
  return LysRequest.request({
    url: `/book/noteCatalogByBookId`,
    method: "post",
    data
  })
}

// 查询用户章笔记列表
export const reqBookNoteList = (data)=>{
  return LysRequest.request({
    url: `/bookNote/searchBookNotePage`,
    method: "post",
    data
  })
}

// 删除用户书籍章笔记
export const reqBookNoteDel = (data)=>{
  return LysRequest.request({
    url: `/bookNote/deleteBookNote`,
    method: "post",
    data
  })
}

// 查询笔记科目
export const reqNoteSubject = (data)=>{
  return LysRequest.request({
    url: `/subject/getNoteSubject`,
    method: "post",
    data
  })
}



//  查询用户书籍练习题收藏列表
export const reqUserBookCollectQuestion= (data)=>{
  return LysRequest.request({
    url: `/bookCollection/searchBookQuestionList`,
    method: "post",
    data
  })
}



//  查询用户书籍例题收藏列表
export const reqUserBookCollectExample= (data)=>{
  return LysRequest.request({
    url: `/bookCollection/searchBookExampleQuestionList`,
    method: "post",
    data
  })
}


//  查询用户书籍词条收藏列表
export const reqUserBookCollectEntry= (data)=>{
  return LysRequest.request({
    url: `/bookCollection/searchBookEntryList`,
    method: "post",
    data
  })
}

// 查询用户书籍笔记收藏列表
export const reqUserBookCollectNote= (data)=>{
  return LysRequest.request({
    url: `/bookCollection/searchBookNoteList`,
    method: "post",
    data
  })
}

// 查询用户书籍视频收藏列表
export const reqUserBookCollectVideo= (data)=>{
  return LysRequest.request({
    url: `/bookCollection/searchBookVideoList`,
    method: "post",
    data
  })
}


// 查询用户书籍音频收藏列表
export const reqUserBookCollectAudio= (data)=>{
  return LysRequest.request({
    url: `/bookCollection/searchBookAudioList`,
    method: "post",
    data
  })
}
