import { defineStore } from "pinia"
import {
  requestLogin,
  requestregister,
  requestLogout,
  requestMobileCode,
  requestMobileCodeCorrect,
  requestPhoneLogin,
  requestUserExist,
  requestUpdatePassword,
  requestUserCancel,
  requestWXLoginQrCode,
  requestWXLogin
} from "@/service/modular/login"
import { requestUserOnLine } from "@/service/modular/home"
import { ElMessage } from "element-plus"
import { useRouter } from "vue-router"
import UseStoreUser from "@/store/modules/user"
import UseStoreHome from "@/store/modules/home"

const UseStoreLogin = defineStore("storeLogin", {
  state: () => ({
    router: useRouter(),
    storeUser: UseStoreUser(),
    storeHome: UseStoreHome(),
    isLogin: false,
    isRegister: false,
    isForgotPassword: false,
    userLogout: false,
    token: localStorage.getItem("project-token") || "",
    wxLoginInfo:{},
    openId:"" // 	微信OpenID
  }),
  actions: {
    
    async requestMobileCodeAction(mobile) {
      return new Promise(async (resolve, reject) => {
        let res = await requestMobileCode(mobile)
        if (res?.code == 200) {
          resolve(res.data)
        } else {
          ElMessage({ message: res.msg, type: "warning" })
        }
      })
    },

    async requestMobileCodeCorrectAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestMobileCodeCorrect(parameter)
        if (res?.code == 200) {
          resolve(res.data)
        }
      })
    },

    //  账号登录
    async requestLoginActions(login) {
      let res = await requestLogin(login)
      if (res.code == 200) {
        this.token = res.data.token
        this.storeUser.userId = res.data.id
        localStorage.setItem("project-token", res.data.token)
        ElMessage({ message: "登录成功", type: "success" })
        this.isLogin = false
        this.storeUser.requestUserInfoActions()

        this.userLoginUpdateData(res.data.id)
     
      
      } else {
        ElMessage({ message: "密码错误，重新填写", type: "error" })
      }
    },

    // 手机登录
    async requestPhoneLoginActions(value) {
      let res = await requestPhoneLogin(value)
      if (res.code == 200) {
        this.token = res.data.token
        this.storeUser.userId = res.data.id
        localStorage.setItem("project-token", res.data.token)
        ElMessage({ message: "登录成功", type: "success" })
        this.isLogin = false
        this.userLoginUpdateData(res.data.id)
      } else {
        ElMessage({ message: res.msg, type: "error" })
      }
    },

    async requestLogoutAction() {
      await requestUserOnLine({
        id: this.storeUser.userId,
        isOnline: 1
      })
      return new Promise(async (resolve, reject) => {
        let res = await requestLogout({
          id: this.storeUser.userId
        })
        if (res.code == 200) {
          this.token = ""
          this.storeUser.userId = ""
          resolve(true)
          ElMessage({ message: "退出成功", type: "success" })
        }
      })
    },

    async requestWXLoginQrCodeAction() {
      let res = await requestWXLoginQrCode()
      if(res.code == 200){
        this.wxLoginInfo = res.data
        return true
      }else{
        return false
      }
    },

    async requestWXLoginAction(uuid){
      let res = await requestWXLogin(uuid)
      if (res.code == 200 ) {
        this.isLogin = false
        if(!res.data.isNewUser){
          this.token = res.data.user.token
          this.storeUser.userId = res.data.user.id
          localStorage.setItem("project-token",  res.data.user.token)
          ElMessage({ message: "登录成功", type: "success" })
          this.storeUser.requestUserInfoActions()
          this.userLoginUpdateData(res.data.user.id)
        }else{
          this.isRegister = true
          this.openId = res.data.openId
        }
        return true
      }else{
        return false
      }
    },


    userLoginUpdateData (userId) {
      this.storeHome.requestQuestionListAction({
        current: 1,
        size: 10,
        userId: userId
      })
      this.storeHome.requestHotVideoListAction({
        currentPage: 1,
        pageSize: 8,
        userId: userId
      })
    },




    // async requestUserInfoActions() {
    //   return new Promise(async (resolve, reject) => {
    //     let res = await requestUserInfo()
    //     if (res?.code == 200) {
    //       this.userInfo = res.data
    //       // 校验用户是否信息必填完
    //       for (const item of this.storeUser.requiredUserInfo) {
    //         for (const key in item) {
    //           if (!res.data[key]) {
    //             this.router.push("/user/personage/information")
    //             resolve(false)
    //           }
    //         }
    //       }
    //       localStorage.setItem(
    //         "project-userInfo",
    //         JSON.stringify({
    //           name: res.data.userName,
    //           id: res.data.id,
    //           studentlD: res.data.studentlD
    //         })
    //       )
    //       resolve(true)
    //     } else {
    //       reject(false)
    //     }
    //   })
    // },

    requestregisterActions(info) {
      return new Promise(async (resolve, reject) => {
        let res = await requestregister(info)
        if (res.code == 200) {
          this.isRegister = false
          this.token = res.data.token
          localStorage.setItem("project-token", res.data.token)
          ElMessage({ message: "注册成功", type: "success" })
          resolve(true)
        } else {
          resolve(false)
        }
      })
    },

    async requestUserExistAction(userName) {
      return new Promise(async (resolve, reject) => {
        let res = await requestUserExist(userName)
        if (res.code == 200) {
          resolve(res.data)
        }
      })
    },

    requestUpdatePasswordAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestUpdatePassword(parameter)
        if (res.code == 200) {
          
          resolve(res.data)
        }
      })
    },

    requestUserCancelAction(parameter) {
      return new Promise(async (resolve, reject) => {
        let res = await requestUserCancel(parameter)
        if (res.code == 200) {
          
          resolve(res.data)
        }
      })
    }
  }
})

export default UseStoreLogin
