import _defineProperty from "E:/\u5DE5\u4F5C\u9879\u76EE\u6587\u4EF6/\u4EE3\u7801/web-education/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import axios from "axios";
import { TIME_OUT, BASE_URL } from "./config";
import UseStoreCommon from "@/store/modules/common.js";
class Request {
  constructor(timeOut, BASE_URL) {
    _defineProperty(this, "loading", void 0);
    this.instance = axios.create({
      timeout: timeOut,
      baseURL: BASE_URL
    });
    this.instance.interceptors.request.use(config => {
      const token = localStorage.getItem("project-token");
      if (token) {
        config.headers.Token = `${token}`;
      }
      UseStoreCommon().loaderToggle = true;
      return config;
    });
    this.instance.interceptors.response.use(res => {
      if (res.data.code == 500) {
        ElMessage({
          message: res.data.msg,
          type: "warning"
        });
      }
      UseStoreCommon().loaderToggle = false;
      return res;
    }, error => {
      UseStoreCommon().loaderToggle = false;
      console.log(error);
    });
  }
  async request(config) {
    return new Promise((resolve, reject) => {
      this.instance.request({
        ...config
      }).then(res => {
        resolve(res?.data);
      }).catch(error => {
        reject(error);
      });
    });
  }
  get(config) {
    return this.request({
      ...config,
      method: "get"
    });
  }
  post(config) {
    return this.request({
      ...config,
      method: "post"
    });
  }
}
export default new Request(TIME_OUT, BASE_URL);