export const resolvingDate = (date) => {
  //date是传入的时间
  // let d = new Date(date);
  // let month = (d.getMonth() + 1) < 10 ? '0'+(d.getMonth() + 1) : (d.getMonth() + 1);
  // let day = d.getDate()<10 ? '0'+d.getDate() : d.getDate();
  // let hours = d.getHours()<10 ? '0'+d.getHours()-8 : d.getHours()-8;
  // let min = d.getMinutes()<10 ? '0'+d.getMinutes() : d.getMinutes();
  // let sec = d.getSeconds()<10 ? '0'+d.getSeconds() : d.getSeconds();
  // let times=d.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec;
  let times = date?.split(".")[0].replace("T", " ")
  return times
}

export const convertToTime = (seconds) => {
  if (seconds) {
    let hours = Math.floor(seconds / 3600)
    let minutes = Math.floor((seconds % 3600) / 60)
    minutes = minutes < 10 ? "0" + minutes : minutes
    let remainingSeconds = seconds % 60
    remainingSeconds = remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds
    if (hours) {
      return `${hours}:${minutes}:${remainingSeconds}`
    } else {
      return `${minutes}:${remainingSeconds}`
    }
  }
}

export const formatTime = (time1, num = 1) => {
  let time = time1?.split(".")[0].replace("T", " ")
  let timeArr = [
    365 * 24 * 60 * 60 * 1000,
    30 * 24 * 60 * 60 * 1000,
    7 * 24 * 60 * 60 * 1000,
    24 * 60 * 60 * 1000,
    60 * 60 * 1000,
    60 * 1000,
    1000
  ]
  let unit = ["年", "个月", "周", "天", "小时", "分钟", "秒钟"]
  let timeDiff = Date.now() - new Date(time).getTime()
  if (timeDiff <= 0) return "刚刚"
  let tip = []
  for (let i = 0; i < timeArr.length; i++) {
    if (timeDiff < timeArr[i]) continue
    let temp = Math.floor(timeDiff / timeArr[i])
    timeDiff = timeDiff % timeArr[i]
    if (temp > 0) {
      tip.push(temp + unit[i])
    }
    if (tip.length >= num) break
  }
  return tip.join("") + "前"
}
