import { defineStore } from "pinia";
import { reqBookCatalogContent, reqBookCatalog, reqBookConceptEntry, reqBookNote, reqBookNoteList, reqBookNoteDel, reqBookChapterVideoDetail, reqBookRecordReadingTime, reqBookCollectType, reqBookDeleteCollectType, reqBookChapterExplainList, reqBookChapterAudioDetail } from "@/service/modular/book";
import UseStoreUser from "@/store/modules/user";
const UseStoreBook = defineStore("storeBook", {
  state: () => ({
    storeUser: UseStoreUser(),
    bookEntryId: [],
    // 词条id
    bookExampleId: [],
    // 例题id
    bookPracticeId: [],
    // 练习题id

    bookLoadModule: false,
    bookReadingTime: null,
    chapterNote: {
      list: [],
      total: 0
    },
    bookLoad: true,
    bookId: "",
    bookName: "",
    chapterId: "",
    sectionId: "",
    pointId: "",
    chapterName: "",
    chapterContent: {},
    bookChapter: [],
    bookSection: [],
    bookChapterExplain: {
      list: [],
      total: 0
    },
    // 章视频列表
    bookChapterExplainDetail: {},
    // 章讲解详情
    explainDialog: false
  }),
  actions: {
    async reqBookCatalogContentAction(data) {
      return new Promise(async (resolve, reject) => {
        let res = await reqBookCatalogContent(data);
        if (res && res.code == 200) {
          console.log(res.data);
          this.bookId = res.data.catalog.bookId;
          this.bookName = res.data.bookName;
          this.chapterName = res.data.catalog.title;
          this.chapterContent = res.data.catalog;
          resolve(true);
        }
      });
    },
    reqBookCatalogAction() {
      return new Promise(async (resolve, reject) => {
        let res = await reqBookCatalog(this.bookId);
        if (res.code == 200) {
          this.bookChapter = res.data.map(chapterItem => {
            if (chapterItem.id == this.chapterId) {
              this.bookSection = chapterItem.chapters.map(sectionItem => {
                return {
                  id: sectionItem.id,
                  title: sectionItem.title,
                  point: sectionItem.contents
                };
              });
            }
            return {
              id: chapterItem.id,
              title: chapterItem.title
            };
          });
          resolve(true);
        }
      });
    },
    reqBookConceptEntryAction(data) {
      return new Promise(async (resolve, reject) => {
        let res = await reqBookConceptEntry(data);
        if (res.code == 200) {
          resolve(res.data);
        }
      });
    },
    reqBookNoteAction(data) {
      return new Promise(async (resolve, reject) => {
        let res = await reqBookNote(data);
        if (res.code == 200) {
          resolve(res.data);
        }
      });
    },
    reqBookNoteListAction(data) {
      return new Promise(async (resolve, reject) => {
        let res = await reqBookNoteList(data);
        if (res.code == 200) {
          this.chapterNote.list = res.data.records;
          this.chapterNote.total = res.data.total;
          resolve(true);
        }
      });
    },
    reqBookNoteDelAction(data) {
      return new Promise(async (resolve, reject) => {
        let res = await reqBookNoteDel(data);
        if (res.code == 200) {
          resolve(true);
        }
      });
    },
    async reqBookChapterExplainListAction(data) {
      let res = await reqBookChapterExplainList(data);
      if (res.code == 200) {
        this.bookChapterExplain.list = res.data.records;
        this.bookChapterExplain.total = res.data.total;
      }
    },
    async reqBookChapterExplainDetailAction({
      type,
      id
    }) {
      this.bookChapterExplainDetail = {};
      let res;
      let data = {
        id,
        userId: this.storeUser.userId
      };
      if (type == 1) {
        res = await reqBookChapterVideoDetail(data);
      } else if (type == 2) {
        res = await reqBookChapterAudioDetail(data);
      }
      if (res.code == 200) {
        this.explainDialog = true;
        this.bookChapterExplainDetail = res.data;
      }
    },
    async reqBookRecordReadingTimeAction() {
      let res = await reqBookRecordReadingTime({
        bookId: this.bookId,
        catalogId: this.chapterId,
        sectionId: this.sectionId,
        userId: this.storeUser.userId
      });
      if (res.code == 200) {
        return res.data;
      }
    },
    // 收藏书籍 不同模块
    async reqBookCollectTypeAction(data) {
      let res = await reqBookCollectType(data);
      if (res.code = 200) {
        ElMessage({
          message: "收藏成功",
          type: "success"
        });
        return res.data;
      }
    },
    // 取消收藏书籍 不同模块
    async reqBookDeleteCollectTypeAction(data) {
      let res = await reqBookDeleteCollectType(data);
      if (res.code = 200) {
        ElMessage({
          message: "取消成功",
          type: "success"
        });
        return res.data;
      }
    }
  }
});
export default UseStoreBook;