import "core-js/modules/es.array.push.js";
import { ref, onMounted } from "vue";
import UseStoreCommon from "@/store/modules/common";
let storeCommon;
const speakingStatus = ref(window.speechSynthesis.speaking); //播放状态

//文字转语音
export const ttsInstantiate = list => {
  onMounted(() => {
    storeCommon = UseStoreCommon();
  });
  let index = 0;
  let urlList = [];

  //播放音标
  const playItemPhonetic = url => {
    // isAudio.value = !isAudio.value;
    const instance = new Audio();
    instance.src = url;
    instance.load();
    // if (isAudio.value) {
    // instance.crossOrigin = "*";
    instance.oncanplaythrough = () => {
      instance.play();
    };
    instance.onended = () => {
      // isAudio.value = false;
      // 播放结束触发，自定义播放按钮重置
    };
    // } else {
    //   instance.pause();
    // }
  };

  let instance;

  //播放音标
  const playPhonetic = () => {
    let playStatus = false;
    speakingStatus.value = true;
    instance = new Audio();
    instance.src = urlList[index].audioUrl;
    instance.load();
    // if (isAudio.value) {
    // instance.crossOrigin = "*";
    instance.oncanplaythrough = () => {
      instance.play();
    };
    instance.onended = () => {
      if (index == urlList.length - 1) {
        speakingStatus.value = false;
        instance.pause();
        return;
      }
      ;
      playStatus = true;
      index++;
      playPhonetic();
      // isAudio.value = false;
      // 播放结束触发，自定义播放按钮重置
    };
  };

  const openVoice = function () {
    let selectedText = window.getSelection(); // 获取选中的文本对象
    if (selectedText.toString()?.length > 0 || storeCommon.labelText?.length) {
      //如果选中音标播放音标
      if (selectedText.anchorNode.parentNode.className === 'phonetic' || selectedText.focusNode.parentNode.className === 'phonetic') {
        if (!speakingStatus.value) {
          let text = selectedText.toString();
          for (let i = 0; i < list.length; i++) {
            if (text.indexOf(list[i].symbol) !== -1) {
              urlList.push(list[i]);
            }
          }
          if (urlList.length) {
            playPhonetic();
          }
        } else {
          index = 0;
          urlList = [];
          speakingStatus.value = false;
          instance.pause();
        }
      } else {
        if (!speakingStatus.value) {
          if (selectedText.toString()) {
            let speech = new SpeechSynthesisUtterance();
            speech.text = selectedText.toString(); // 内容
            speech.lang = "zh-cn"; // 语言
            speech.voiceURI = "Microsoft Huihui - Chinese (Simplified, PRC)"; // 声音和服务
            // eslint-disable-next-line no-irregular-whitespace
            speech.volume = 0.7; // 声音的音量区间范围是​​0​​​到​​1默认是​​1​​
            // eslint-disable-next-line no-irregular-whitespace
            speech.rate = 0.8; // 语速，数值，默认值是​​1​​​，范围是​​0.1​​​到​​10​​​，表示语速的倍数，例如​​2​​表示正常语速的两倍
            // eslint-disable-next-line no-irregular-whitespace
            speech.pitch = 1; // 表示说话的音高，数值，范围从​​0​​​（最小）到​​2​​​（最大）。默认值为​​1​​。
            window.speechSynthesis.speak(speech);
            pollingPlayStatus();
          } else {
            ElMessage({
              type: "warning",
              message: "请选中文本"
            });
          }
        } else {
          speakingStatus.value = false;
          window.speechSynthesis.cancel();
        }
      }
    } else {
      ElMessage({
        type: "warning",
        message: "请选中文本"
      });
    }
  };
  //轮询播放状态
  const pollingPlayStatus = () => {
    let times;
    times = setTimeout(() => {
      clearTimeout(times);
      speakingStatus.value = window.speechSynthesis.speaking;
      if (speakingStatus.value) {
        pollingPlayStatus();
      }
    }, 100);
  };
  return {
    openVoice,
    speakingStatus,
    playItemPhonetic
  };
};