import LysRequest from "../request/index"

// 购物车数据
export const requestCart = (userId) => {
  return LysRequest.request({
    url: `/userBookCollection/byUserId/${userId}`,
    method: "get"
  })
}

// 购物车删除
export const requestCartDelete = (parameter) => {
  return LysRequest.request({
    url: `/userBookCollection/remove/${parameter.userId}/${parameter.bookId}`,
    method: "post"
  })
}

// 批量删除购物车
export const requestRemoveByIds = (data) => {
  return LysRequest.request({
    url: `/userBookCollection/removeByIds`,
    method: "post",
    data
  })
}

// 购物车书籍推荐
export const requestCartBook = () => {
  return LysRequest.request({
    url: `/book/getRandomRecommendBookInfo`,
    method: "post"
  })
}

// 支付宝支付接口
export const requestZfbPay = (data) => {
  return LysRequest.request({
    url: `/pay/aliPay`,
    method: "post",
    data
  })
}

// 微信支付接口
export const requestWxPay = (data) => {
  return LysRequest.request({
    url: `/pay/wxPay`,
    method: "post",
    data
  })
}

// 查询订单状态
export const requestPayStatus = (orderNumber) => {
  return LysRequest.request({
    url: `/pay/status/${orderNumber}`,
    method: "post"
  })
}

// 查询用户余额订单明细
export const requestUserBalance = (data) => {
  return LysRequest.request({
    url: `/userBookOrder/balanceOrder`,
    method: "post",
    data
  })
}
